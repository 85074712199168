import { DefaultOptions } from "@tanstack/react-query";
import apiInterceptor from "./axiosConfig";

interface QueryClientConfig {
  defaultOptions?: DefaultOptions;
}

const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      retryDelay: 3000,
      staleTime: 3 * 1000,
    },

  },
}

export default queryClientConfig;
